// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// var io = require("packs/socket.io")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// socket = io.connect(wsdomain);


function put_op(field_name, operator) {
  $(`thead input[name="${field_name}"]`).data('op',operator);
}

// version a, untuk model row
var filtering_and_go = function(urlfor, page, rows, sort, order) {

  var result = [];

  // ambil bagian input saja
  $.each($('#head_search input'), function(a, b) {
    var field = b.name;
    var op = $(this).data('op');
    var valx = $(this).val();

    if(valx.length !== 0) {
      result.push({"field": field, "op": op, "value": valx})
    }
  })

  // ambil bagian select jika ada 
  $.each($('#head_search select'), function(a, b) {
    var field = b.name;
    var op = $(this).data('op');
    var valx = $(this).val();

    if(valx.length !== 0) {
      result.push({"field": field, "op": op, "value": valx})
    }
  })

  window.location.href = urlfor + '?page=' + page + '&rows='+ rows + '&sort=' + sort + '&order=' + order + '&filter_rules=' + JSON.stringify(result)
}
var adding_search_head_value_for_filtering_and_go = function(filter_rules_string) {

  var filter_rules = filter_rules_string;

  var obj = JSON.parse(filter_rules);

  // tempatkan filter jika input
  $.each(obj, function(a, v){

    $(`#head_search input[name="${v.field}"]`).val(v.value);
    $(`#head_search input[name="${v.field}"]`).data('op',v.op);

    $(`#head_search select[name="${v.field}"]`).val(v.value);
    $(`#head_search select[name="${v.field}"]`).data('op',v.op);
    
  })
}

// version b. Untuk model grid
var filtering_and_go_vb = function(urlfor, page, rows, sort, order) {

  var result = [];

  $.each($('thead input'), function(a, b) {
    var field = b.name;
    var op = $(this).data('op');
    var valx = $(this).val();

    console.log('this',$(this));
    console.log(field);
    console.log('op',op);
    console.log(valx);

    if(valx.length !== 0) {
        result.push({"field": field, "op": op, "value": valx})
    }
  })

  window.location.href = urlfor + '?page=' + page + '&rows='+ rows + '&sort=' + sort + '&order=' + order + '&filter_rules=' + JSON.stringify(result)
}